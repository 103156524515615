import mapVerify from '@/utils/formValidate'

// 获取{{ $t('lang.gles.common.moreQuery') }}字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 产线编码
    {
      name: 'productionLineCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.productionLineCode'),
      clearable: true
    },
    // 产线名称
    {
      name: 'productionLineName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.productionLineName'),
      clearable: true
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      clearable: true,
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      clearable: true,
      filterable: true,
      data: that.workshopList,
      mchange: that.handleWorkshopChange
    },
    // 所属区域
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      clearable: true,
      filterable: true,
      data: that.areaList
    }
  ]
  moreQueryData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 产线编码
    {
      prop: 'productionLineCode',
      label: that.$t('lang.gles.base.productionLineCode'),
      isShow: true,
      sortable: true
    },
    // 产线名称
    {
      prop: 'productionLineName',
      label: that.$t('lang.gles.base.productionLineName'),
      isShow: true
    },
    // 所属工厂
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true
    },
    // 所属车间
    {
      prop: 'workshopName',
      label: `${that.$t('lang.gles.base.belongingWorksop')}`,
      isShow: true
    },
    // 所属区域
    {
      prop: 'areaName',
      label: `${that.$t('lang.gles.base.belongingArea')}`,
      isShow: true
    },
    // 产线描述
    {
      prop: 'description',
      label: `${that.$t('lang.gles.base.productionLineDesc')}`,
      isShow: true
    }
  ]

  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))

  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 产线编码
    {
      name: 'productionLineCode',
      value: row.productionLineCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.productionLineCode'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      clearable: true,
      class: 'tip-icon'
    },
    // 产线名称
    {
      name: 'productionLineName',
      value: row.productionLineName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.productionLineName'),
      placeholder: '200个字符以内，支持任意字符',
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      rules: mapVerify(['required']),
      clearable: true,
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      value: row.workshopId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      rules: mapVerify(['required']),
      clearable: true,
      filterable: true,
      data: that.workshopList,
      mchange: that.handleWorkshopChange
    },
    {
      name: 'areaId',
      value: row.areaId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      rules: mapVerify(['required']),
      clearable: true,
      filterable: true,
      data: that.areaList
    },
    // 产线描述
    {
      name: 'description',
      value: row.productionLineDesc || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.productionLineDesc'),
      clearable: true,
      rules: mapVerify(['inputLen500'])
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}

<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon>
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="$t(autoGeneCodeTip)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'

export default {
  name: 'ProductionLineAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins, commonMixins],
  props: {
    // 新增/修改模式
    mode: {
      type: String,
      default: 'add'
    },
    // 编辑或者查看详情 行信息
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.base.productionLineAdd')]
        : [this.$t('lang.gles.base.productionLineEdit')]
    },
    baseFormData() {
      return getEditBaseFormData(this, { ...this.formModel })
    }
  },
  created() {
    this.formModel = { ...this.rowDetail }
    if (this.mode === 'add') {
      this.getSerialBizCode('productionLineSerialNum', 'productionLineCode')
    }
    this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getFactoryList()
      if (this.mode === 'edit') {
        this.getWorkshopList({ factoryId: this.formModel.factoryId })
        this.getAreaList({ workshopId: this.formModel.workshopId })
      }
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetWorkshop(this.formModel, 'cascade')
      this.resetArea(this.formModel)
    },
    /**
     * 所属车间 change
     * 车间-区域
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      const type = val ? 'cascade' : null
      this.resetArea(this.formModel, type)
    },
    /**
     * 保存
     */
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        const params = { ...this.formModel }
        this.mode === 'add'
          ? await this.insert(params)
          : await this.update(params)
      })
    },
    /**
     * 新增
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('insertProductionLine'),
        params
      )
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
    },
    /**
     * 更新
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateProductionLine'),
        params
      )
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'ProductionLineList'
      })
    }
  }
}
</script>
